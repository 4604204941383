import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, HeadProps } from 'gatsby'
import { Layout, BlockRenderer, HeadTags, Footer, Panel } from '@components'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

interface PageProps {
  data: Queries.IndexPageQuery
}

const IndexPage = ({ data: { sanityPage } }: PageProps) => {
  if (!sanityPage) return <></>
  const [t, setT] = useState<number>(0)
  useScrollPosition(
    ({ currPos }) => setT(currPos.y), // effect callback
    [], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    10, // performance debounce
  )
  return (
    <Layout>
      {sanityPage.panels?.map(
        (panel, i) => !!panel && !panel.hidden && <Panel key={`panel-${i}`} z={i} panel={panel} />,
      )}
      <ContentWrapper>
        <Content>
          {sanityPage?.copy?.map(
            (copySet, i) =>
              !!copySet && (
                <CopySet key={`copyset-${i}`}>
                  <Copy>
                    <BlockRenderer>{copySet._rawCopy!}</BlockRenderer>
                  </Copy>
                </CopySet>
              ),
          )}
        </Content>
      </ContentWrapper>
      <Footer />
    </Layout>
  )
}

const ContentWrapper = styled.div`
  position: relative;
  z-index: 9999;
  padding: 20px;
  min-height: calc(100vh - 56px);
  min-height: calc(100dvh - 56px);
  @media only screen and (min-width: 744px) {
    min-height: calc(100vh - 52px);
    padding: 80px 40px;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #111111;
  color: white;
`

const Content = styled.article`
  position: relative;
  z-index: 10;
  @media only screen and (min-width: 744px) {
    column-count: 2;
    column-gap: 40px;
  }
  @media only screen and (min-width: 1200px) {
    column-count: 2;
    max-width: 900px;
  }
`

const CopySet = styled.div`
  break-inside: avoid;
  padding-bottom: 1em;
`

const Title = styled.h2`
  font-weight: 700;
`

const Copy = styled.section``

export const query = graphql`
  fragment Panel on SanityPanel {
    _rawCopy
    color
    xAlign
    yAlign
    fadeIn
    hidden
    size
    image {
      asset {
        url
        metadata {
          lqip
        }
      }
    }
    video {
      asset {
        url
      }
    }
  }
  query IndexPage {
    sanityPage(slug: { current: { eq: "index" } }) {
      title
      copy {
        title
        _rawCopy
      }
      panels {
        ...Panel
      }
    }
  }
`

export const Head = (props: HeadProps) => <HeadTags {...props}></HeadTags>

export default IndexPage
